<template>
    <div class="container-fluid">
        <list-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :loading="loading"
            :pagination="getP"
            :queriedData="queriedData"
            :data="data"
            :total="total"
            v-on:load_credit_back="loadCreditSaleBack"
            v-on:search_q="searchBack"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:details="details"
            v-on:load_credits="loadCreditPlafonds"  
            v-on:credit_report="creditReport"
            v-on:add_or_edit="addOrEdit"
            v-on:delete_plafond="deleteCreditPlafond"
        >
        </list-desktop>

        <list-mobile
            v-if="$isMobile()"
            :empty="empty"
            :loading="loading"
            :pagination="getP"
            :queriedData="queriedData"
            :data="data"
            :total="total"
            v-on:add_or_edit="addOrEdit"
            v-on:details="details"
            v-on:search_q="searchBack"
            v-on:load_credits="loadCreditPlafonds" 
            v-on:per_page_selected="nbrPerPageSelected" 
            v-on:credit_report="creditReport"
            v-on:load_credit_back="loadCreditSaleBack"
            v-on:delete_plafond="deleteCreditPlafond"
        >
        </list-mobile>
     
    <!-- Details -->
    <mdb-modal
        centered
        size="md" 
        direction="top"
        position="top"
        :show="modalDetailsCreditSale.show" 
        @close="modalDetailsCreditSale.show = false" 
    >
        <form @submit.prevent=''>
        <mdb-modal-header>
            <mdb-modal-title>{{ modalDetailsCreditSale.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body >
            <table class="table">
                <tr>
                    <th>Client</th>
                    <td v-if="modalDetailsCreditSale.content.customer">{{modalDetailsCreditSale.content.customer.designation}}</td>
                </tr>
                <tr>
                    <th>Email Client</th>
                    <td v-if="modalDetailsCreditSale.content.customer">{{modalDetailsCreditSale.content.customer.email}}</td>

                </tr>
                <tr>
                    <th>Initié par</th>
                    <td v-if="modalDetailsCreditSale.content.initiated_by" style="text-transform:capitalize">{{modalDetailsCreditSale.content.initiated_by.firstname}} {{modalDetailsCreditSale.content.initiated_by.lastname}}</td>

                </tr>
                <tr>
                    <th>Plafond de crédit</th>
                    <td>{{ new Intl.NumberFormat("BJ-bj").format(modalDetailsCreditSale.content.credit_plafond)}} XOF</td>
                </tr>
            </table>
        </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn  v-if="modalDetailsCreditSale.content.status === 'progress' && $store.state.auth.user.is_approval_credit_plafond === 1" size="md" color="success" @click="validate(modalDetailsCreditSale.content)" :disabled="modalDetailsCreditSale.btn">
                    <span v-if="!modalDetailsCreditSale.btnValidated">Valider</span>
                    <span v-if="modalDetailsCreditSale.btnValidated" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalDetailsCreditSale.btnValidated" class="pl-2">Valider</span>
                </mdb-btn>
                <mdb-btn v-if="modalDetailsCreditSale.content.status === 'progress' && $store.state.auth.user.is_approval_credit_plafond === 1" size="md" color="danger" @click="reject(modalDetailsCreditSale.content)" :disabled="modalDetailsCreditSale.btn">
                    <span v-if="!modalDetailsCreditSale.btnRejected">Rejeter</span>
                    <span v-if="modalDetailsCreditSale.btnRejected" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalDetailsCreditSale.btnRejected" class="pl-2">Rejeter</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalDetailsCreditSale.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
        </form>
    </mdb-modal>

    <!-- Details -->
    <mdb-modal
        centered
        size="md" 
        direction="top"
        position="top"
        :show="modalCreditSale.show" 
    >
        <form @submit.prevent='modalCreditSale.edit ? update() : save()'>
        <mdb-modal-header>
            <mdb-modal-title>{{ modalCreditSale.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body >
            <fieldset>
                <mdb-row>
                    <mdb-col sm="12" md="12" lg="6">
                        <mdb-select
                            flipOnScroll
                            search
                            v-model="customerOption"
                            @getValue="getCustomer"
                            outline
                            label="Client"
                        ></mdb-select>
                        <div
                            style="color: #dc3545; font-size: 0.8rem" class="mt-n3"
                            v-if="$store.state.credit_plafond_customer.errors.customer_id"
                            >
                            {{ $store.state.credit_plafond_customer.errors.customer_id }}
                        </div>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="6">
                        <mdb-input
                            :validation="$store.state.credit_plafond_customer.errors.credit_plafond ? true : false"
                            :invalidFeedback="$store.state.credit_plafond_customer.errors.credit_plafond"
                            v-model="formcu.credit_plafond"
                            size="md" outline label="Plafond de crédit" ></mdb-input>
                    </mdb-col>
                </mdb-row>
            </fieldset>
        </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit" :disabled="modalCreditSale.btn">
                    <span v-if="!modalCreditSale.btn">Sauvegarder</span>
                    <span v-if="modalCreditSale.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalCreditSale.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalCreditSale.show = false">Fermer</mdb-btn>
            </mdb-modal-footer>
        </form>
    </mdb-modal>

    <!-- Exports -->
        <mdb-modal
            size="md" 
            direction="top"
            position="top"
            :show="modalDeptReport.show"
        > 
            <form @submit.prevent="downloadReport()">
            <mdb-modal-header>
                <mdb-modal-title>{{ modalDeptReport.title }}</mdb-modal-title><br>
            </mdb-modal-header>
            <mdb-modal-body class="">
                <mdb-row>
                    <mdb-col sm="12" md="12">
                            <mdb-select
                                flipOnScroll
                                v-model="typeReportOption"
                                @getValue="getTypeReportDebtReport"
                                outline
                                label="Type de rapport"
                            ></mdb-select>
                        </mdb-col>
                    <mdb-col sm="12" md="6">
                            <mdb-date-picker-2
                                :options="option"
                                v-mask="'####-##-##'"
                                autoHide
                                outline
                                v-model="formCreditSaleReport.from"
                                :defaultDate="formCreditSaleReport.from"
                                label="Date début"
                                title="Date début"
                            />
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-date-picker-2
                                :options="option"
                                v-mask="'####-##-##'"
                                autoHide
                                outline
                                v-model="formCreditSaleReport.to"
                                :defaultDate="formCreditSaleReport.to"
                                label="Date fin"
                                title="Date fin"
                            />
                        </mdb-col>
                        <mdb-col sm="12" md="6" v-if="formCreditSaleReport.typeReport === 'detailed'">
                            <mdb-select
                                flipOnScroll
                                search
                                multiple
                                selectAll
                                v-model="customerOption"
                                @getValue="getCustomerDebtReport"
                                outline
                                label="Clients"
                            ></mdb-select>
                        </mdb-col>
                        <mdb-col sm="12" md="6" v-if="$gate.checkTypeRole(['super-admin', 'admin'])">
                            <mdb-select
                                flipOnScroll
                                search
                                multiple
                                selectAll
                                v-model="userOption"
                                @getValue="getUserDebtReport"
                                outline
                                label="Utilisateurs"
                            ></mdb-select>
                        </mdb-col>
                        <mdb-col sm="12" md="6" v-if="$gate.checkTypeRole(['super-admin', 'admin'])">
                            <mdb-select
                                flipOnScroll
                                search
                                multiple
                                selectAll
                                v-model="siteOption"
                                @getValue="getSiteDebtReport"
                                outline
                                label="Sites"
                            ></mdb-select>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-select
                                flipOnScroll
                                v-model="formatOption"
                                @getValue="getFormatDebtReport"
                                outline
                                label="Format d'export"
                            ></mdb-select>
                        </mdb-col>
                </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit"  :disabled="modalDeptReport.btn" >
                    <span v-if="!modalDeptReport.btn">Exporter le rapport</span>
                    <span v-if="modalDeptReport.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalDeptReport.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalDeptReport.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
            </form>
        </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from './modules/ListDesktop'
import ListMobile from './modules/ListMobile'
import download from "../../../services/download-fetch"

import {
    mdbBtn,
    mdbRow,mdbCol,
    mdbSelect,
    mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
    mdbDatePicker2,
    // mdbSwitch,
} from 'mdbvue'
export default {
    name:"creditSales",
    metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - Plafonds de crédit",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
    components:{
        mdbBtn,
        mdbRow,mdbCol,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
        mdbDatePicker2,
        mdbSelect,
    mdbInput,

        // mdbSwitch,
        ListDesktop,ListMobile
    },

    data(){
        return {
            option: {
                week: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
                month: [
                    "Janvier",
                    "Février",
                    "Mars",
                    "Avril",
                    "Mai",
                    "Juin",
                    "Juillet",
                    "Août",
                    "Septembre",
                    "Octobre",
                    "Novembre",
                    "Décembre",
                ],
                placeholder: "Choisir une date",
                buttons: {
                    ok: "Ok",
                    clear: "Effacer",
                    cancel: "annuler",
                    today: "Aujourd hui",
                },
            },
            loading:false,
            loadingCreditPld:false,
            dataPagination: {
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "customer_id",
                    "user_id",
                    "credit_plafond",
                    "initiated_by",
                    "initiated_at",
                    "status",
                ],
            },

            formatOption: [
                {text:"XLSX",value:"xlsx"},
                {text:"CSV",value:"csv"},
                {text:"PDF",value:"pdf", selected: true},
            ],

            typeReportOption: [
                {text:"Rapport détaillé",value:"detailed", selected: true},
                {text:"Rapport combiné",value:"combined"},
            ],

            empty:false,
            data:{
                columns: [
                    {
                        label: "Client",
                        field: "customer",
                        sort: true,
                    },
                    {
                        label: "Plafond de crédit",
                        field: "credit",
                        sort: true,
                    },
                    {
                        label: "Inité par",
                        field: "initiated",
                        sort: true,
                    },
                    {
                        label: "Inité le",
                        field: "created_at",
                        sort: true,
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: true,
                    },
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                credit_plafond_customer: {
                    current_page: 1,
                    data: [],
                },
            },

            customers: [],
            customerOption: [],
            users: [],
            userOption: [],

            form:{
                id:"",
                customer_id:'',
                status:"progress"
            },

            formcu:{
                id:"",
                credit_plafond:0,
                customer_id:'',
                approved_at: "",
                approved_by: "",
                initiated_by: "",
                company_id: "",
            },

            formCreditSaleReport:{
                from:this.$moment().format("YYYY-MM-DD"),
                to:this.$moment().format("YYYY-MM-DD"),
                format:"pdf",
                typeReport:"detailed",
                customers:[],
                articles:[],
                users:[],
                sites:[],
            },

            modalDetailsCreditSale: {
                show: false,
                edit: false,
                title: "Plafonds de crédit",
                content: {},
                btnValidated:false,
                btnRejected:false
            },

            modalCreditSale: {
                show: false,
                edit: false,
                title: "Plafonds de crédit",
                content: {},
                btn:false
            },

            modalDeptReport: {
                show: false,
                edit: false,
                title: "Exporter des rapports",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalCreditSale.title = "Modifier un plafond de crédit";
                this.modalCreditSale.content = data;
                this.modalCreditSale.edit = true;
                this.formRest(data);
            } else {
                this.modalCreditSale.title = "Ajouter un plafond de crédit";
                this.modalCreditSale.content = {};
                this.modalCreditSale.edit = false;
                this.cleanForm("credit_plafond_customer");
            }
            this.modalCreditSale.show = true;
        },

        details(data = null) {
            this.modalDetailsCreditSale.content = data;
            this.modalDetailsCreditSale.show = true;
        },


        cleanForm(type) {
            if (type === 'credit_plafond_customer') {
				this.formcu.id = ""
                this.formcu.credit_plafond = 0
                this.formcu.customer_id = ''
                this.formcu.approved_at =  ""
                this.formcu.approved_by =  ""
                this.formcu.initiated_by =  ""
                this.formcu.company_id =  ""

                this.customerOption.forEach((el) => {
                    el.selected = false
                });
			}
        },

        formRest(data) { 
            this.formcu.id =  data.id
            this.formcu.credit_plafond = data.credit_plafond
            this.formcu.customer_id =  data.customer_id
            this.formcu.approved_at =   data.approved_at
            this.formcu.approved_by =   data.approved_by
            this.formcu.initiated_by =   data.initiated_by
            this.formcu.company_id =   data.company_id

            this.customerOption.forEach((el) => {
                 if(el.value === data.customer.id){
                    el.selected = true
                 }else{
                    el.selected = false
                 }
            });
        },

        async loadCreditPlafonds ({ page, perPage }) {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('credit_plafond_customer/findAll', {
                page: page,
                perPage: perPage,
            })
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.data.data.length > 0) {
                    this.empty = false;
                } else {
                    this.empty = true;
                }
                this.data.rows = response.data.data
                this.data.credit_plafond_customer = response.data.data;
                this.dataPagination.pagination.currentPage =
                    response.data.data.current_page;
                this.dataPagination.pagination.perPage = response.data.data.per_page;
                this.dataPagination.pagination.total = response.data.data.total;
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async validate(data) {
            if(this.$store.state.auth.user.is_approval_credit_plafond === 0){
                return
            }
            this.modalDetailsCreditSale.btnValidated = !this.modalDetailsCreditSale.btnValidated
			await this.$store.dispatch('credit_plafond_customer/approbation', {
                id: data.id,
                status: "validated",
            })
			.then((response) => {
				this.modalDetailsCreditSale.show = false
                this.modalDetailsCreditSale.btnValidated = !this.modalDetailsCreditSale.btnValidated
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                this.loadCreditPlafonds({
                    page:  this.data.credit_plafond_customer.current_page,
                    perPage: this.dataPagination.pagination.perPage,
                })
			})
			.catch((error) => {
                this.modalDetailsCreditSale.btnValidated = !this.modalDetailsCreditSale.btnValidated
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async reject(data) {
            if(this.$store.state.auth.user.is_approval_credit_plafond === 0){
                return
            }
            this.modalDetailsCreditSale.btnRejected = !this.modalDetailsCreditSale.btnRejected
			await this.$store.dispatch('credit_plafond_customer/approbation', {
                id: data.id,
                status: "rejected",
            })
			.then((response) => {
				this.modalDetailsCreditSale.show = false
                this.modalDetailsCreditSale.btnRejected = !this.modalDetailsCreditSale.btnRejected
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                this.loadCreditPlafonds({
                    page:  this.data.credit_plafond_customer.current_page,
                    perPage: this.dataPagination.pagination.perPage,
                })
			})
			.catch((error) => {
                this.modalDetailsCreditSale.btnRejected = !this.modalDetailsCreditSale.btnRejected
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async save() {
            this.$store.commit('credit_plafond_customer/SET_CLEAN')
            this.modalCreditSale.btn = !this.modalCreditSale.btn

            await this.$store.dispatch('credit_plafond_customer/save', this.formcu)
			.then((response) => {
				this.modalCreditSale.show = false
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('credit_plafond_customer')
                this.loadCreditPlafonds({
                    page: 1,
                    perPage: this.dataPagination.pagination.perPage,
                })
			})
			.catch((error) => {
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                if (error.response.data.errors) {
                    this.$store.commit('credit_plafond_customer/SET_CP', error.response.data.errors.credit_plafond)
                    this.$store.commit('credit_plafond_customer/SET_CI', error.response.data.errors.customer_id)
                }

                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('credit_plafond_customer/SET_CLEAN')
            this.modalCreditSale.btn = !this.modalCreditSale.btn
			await this.$store.dispatch('credit_plafond_customer/update', this.formcu)
			.then((response) => {
				this.modalCreditSale.show = false
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('credit_plafond_customer')
                this.loadCreditPlafonds({
                    page:  this.data.credit_plafond_customer.current_page,
                    perPage: this.dataPagination.pagination.perPage,
                })
			})
			.catch((error) => {
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                if (error.response.data.errors) {
                    this.$store.commit('credit_plafond_customer/SET_CP', error.response.data.errors.credit_plafond)
                    this.$store.commit('credit_plafond_customer/SET_CI', error.response.data.errors.customer_id)
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        getCustomer(value) {
            this.formcu.customer_id = value;
        },
      
        getCustomerDebtReport(value){
            this.formCreditSaleReport.customers = value
        },

        getTypeReportDebtReport(value){
            this.formCreditSaleReport.typeReport = value

            this.$nextTick(() => {
                this.formCreditSaleReport.customers = []

                this.customerOption.forEach(cust => {
                    cust.selected = false
                });
            })
        },

        getUserDebtReport(value){
            this.formCreditSaleReport.users = value
        },

        getSiteDebtReport(value){
            this.formCreditSaleReport.sites = value
        },

        getFormatDebtReport(value){
            this.formCreditSaleReport.format = value
        },

        async loadCustomers() {
            this.customerOption = [];
            this.$nprogress.start();
            await this.$store
            .dispatch("customer/findAll")
            .then((response) => {
                this.$nprogress.done();
                this.customers = response.data.customers.filter(
                    (customer) => customer.is_active === 1 && customer.approuved === 1 
                ).filter((customer) => customer.designation.toLowerCase() !== 'divers');
                this.customers.forEach((customer) => {
                    this.customerOption.push({
                    text: customer.designation,
                    value: customer.id,
                    });
                });
            })
            .catch((error) => {
                this.$nprogress.done();
                this.$notify({
                    message: error.response.data.message || error.response.data.error,
                    type: "danger",
                });
            });
        },

        async loadUsers() {
            if (!this.$gate.checkTypeRole(["super-admin", "admin"])) {
                return (this.users = []);
            }
            this.$nprogress.start();
            await this.$store
                .dispatch("user/findAll")
                .then((response) => {
                this.users = response.data.users;
                this.users.forEach((user) => {
                    this.userOption.push({
                        text: user.lastname + " " + user.firstname,
                        value: user.id,
                    });
                });
                })
                .catch((error) => {
                this.$nprogress.done();
                this.$notify({
                    message: error.response.data.message || error.response.data.error,
                    type: "danger",
                });
            });
        },

        async downloadReport(){
            this.$nprogress.start()

            if (!this.$gate.checkTypeRole(["super-admin","admin"])) {
                this.formCreditSaleReport.users = []
                this.formCreditSaleReport.sites = []

                this.userOption.forEach(cust => {
                    cust.selected = false
                });
                this.siteOption.forEach(cust => {
                    cust.selected = false
                });
            }
            await download({
                isTh:0,
                method:"post",
                data:this.formCreditSaleReport,
                url:"/reports/credit-sales-"+this.formCreditSaleReport.typeReport+"?export="+this.formCreditSaleReport.format,
                filename:"credit_plafond_customers_"+this.formCreditSaleReport.typeReport+"."+this.formCreditSaleReport.format
            }).then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Téléchargé avec succès",
                    type: 'success'
                })
                this.modalDeptReport.show = false
            })
            .catch(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Echec durant le téléchargement",
                    type: 'danger'
                })
            })
        },
        
        async deleteCreditPlafond(id){
            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
            await this.$store.dispatch('credit_plafond_customer/delete',id)
                .then((response) => {
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadCreditPlafonds({
                    page: 1,
                    perPage: this.dataPagination.pagination.perPage,
                })
                })
                .catch(error =>{
                    this.$notify({
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })

        },

        creditReport(){
            this.modalDeptReport.show = true
        },

        loadCreditSaleBack(e) {
        this.loadCreditPlafonds({
            page: e.page,
            perPage: e.perPage,
        });
        },
        searchBack(e) {
        this.dataPagination.searchQuery = e;
        },

        // Systeme de datatable
        totalCalcul() {
        return (this.dataPagination.pagination.total = this.data.credit_plafond_customer.total);
        },

        searchQ() {
        if (!this.dataPagination.searchQuery) {
            // return this.pagedData;
            return new Error("Nothing To Search");
        }

        let result = this.data.credit_plafond_customer.data.filter((row) => {
            let isIncluded = false;
            for (let key of this.dataPagination.propsToSearch) {
                let rowValue = "";
                rowValue = `${row[key]}`.toString().toLowerCase();

                if (key === 'customer_id') {
                    rowValue = `${row['customer'].designation}`.toString().toLowerCase();
                }

                if (key === 'status') {
                    if (row['status'].toLowerCase() === 'en cours') {
                        rowValue = `progress`.toString().toLowerCase();
                    }
                    if (row['status'].toLowerCase() === 'validé') {
                        rowValue = `validated`.toString().toLowerCase();
                    }
                    if (row['status'].toLowerCase() === 'rejeté') {
                        rowValue = `rejected`.toString().toLowerCase();
                    }
                }

                if (key === 'user_id') {
                    rowValue = `${row['initiated_by'].firstname} ${row['initiated_by'].lastname}`.toString().toLowerCase();
                }

                if (key === 'initiated_at') {
                    rowValue = `${this.$moment(row['initiated_at']).format('DD/MM/YYYY à H:mm:ss')}`.toString().toLowerCase();
                }

                if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                isIncluded = true;
                }
            }
            return isIncluded;
        });
        this.dataPagination.pagination.total = result.length;
        return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(e) {
        let nbr = e;
        return this.nbrPerPage(nbr);
        },

        nbrPerPage(nbr) {
        this.loadCreditPlafonds({
            page: 1,
            perPage: nbr,
        });
        return (this.dataPagination.pagination.perPage = nbr);
        },
        setTotal() {
        if (this.dataPagination.searchQuery === "") {
            this.dataPagination.pagination.total = this.data.credit_plafond_customer.total;
        }
        },

        designationFormatter(){
            this.form.designation = this.form.designation.toUpperCase()
        }
    },

    created() {
        this.loadCreditPlafonds({
            page: this.data.credit_plafond_customer.current_page,
            perPage: this.dataPagination.pagination.perPage,
        })  
        this.loadCustomers()  
        this.loadUsers()
    },

    computed: {
        pageData() {
            return this.data.credit_plafond_customer.data;
        },

        
        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.data.credit_plafond_customer.total < parseInt(highBound)) {
                highBound = this.total;
            }
            return parseInt(highBound);
        },
        from() {
            return (
                this.dataPagination.pagination.perPage *
                (this.dataPagination.pagination.currentPage - 1)
            );
        },

        queriedData() {
        let result = this.searchQ();
        this.setTotal();
        return result instanceof Error ? this.pageData : result;
        },

        total() {
        return this.totalCalcul();
        },

        getP() {
        return this.dataPagination;
        },
    }
}
</script>

<style lang="scss" >
.avertissemendebt{
    font-size: .8em;
}
</style>